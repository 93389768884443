<template id="select-contract">
    <div class="card p-fluid m-5">
        <h5>Agregar Actividades a Cumplimiento Mensual Persona</h5>

        <div class="field" v-if="allEmployees.length > 0">
            <autoComplete v-model="selectedAsignedEmployee" field="first_name1" :suggestions="filteredPeople" @complete="searchPeople($event)" placeholder="Seleccione encargado de actividad *"></autoComplete>
            <!--h6 v-if="error.act_asigned_oblig" class="p-error">Seleccion encargado de actividades.</h6-->
        </div>

        <Dropdown v-model="selectedContract" :options="contracts" optionLabel="Nombre" :filter="false" placeholder="Seleccione Contrato *" :showClear="true" :disabled="compliance_detail.length > 0">
            <template #value="slotProps">
                  <div class="p-dropdown-supervisors-value" v-if="slotProps.value">
                      <span>
                        {{slotProps.value.name}}
                      </span>
                  </div>
                  <span v-else>
                      {{slotProps.placeholder}}
                  </span>
            </template>
            <template #option="slotProps">
                  <div class="p-dropdown-supervisors-option">
                    <span>
                          {{slotProps.option.name}}</span>
                  </div>
            </template>
        </Dropdown>

        <Dropdown v-model="selectedMonth" :options="monthNames" optionLabel="Nombre" :filter="false" placeholder="Seleccione Mes *" :showClear="true">
            <template #value="slotProps">
                  <div class="p-dropdown-supervisors-value" v-if="slotProps.value">
                      <span>
                        {{slotProps.value.name}}
                      </span>
                  </div>
                  <span v-else>
                      {{slotProps.placeholder}}
                  </span>
            </template>
            <template #option="slotProps">
                  <div class="p-dropdown-supervisors-option">
                    <span>
                          {{slotProps.option.name}}</span>
                  </div>
            </template>
        </Dropdown>

        <Dropdown id="asscewfddsd" v-model="selectYear" :options="years" :filter="false" placeholder="Seleccione año *" :showClear="true">
              <template #value="slotProps">
                  <div class="p-dropdown-supervisors-value" v-if="slotProps.value">
                      <span>
                        {{slotProps.value}}
                      </span>
                  </div>
                  <span v-else>
                      {{slotProps.placeholder}}
                  </span>
              </template>
              <template #option="slotProps">
              <div class="p-dropdown-supervisors-option">
                <span>
                      {{slotProps.option}}</span>
              </div>
            </template>
        </Dropdown>

        <buttonp class="w-6 mt-5 p-button-warning p-button-sm" label="Ingresar Detalle Actividades" @click.prevent="add_detail_compliance()"/>

        <span v-if="compliance_detail.length > 0">
            <DataTable :value="compliance_detail">
                <Column  header="Tipo Actividad" >
                    <template #body="slotProps">
                        {{slotProps.data.activity_type.name}}
                    </template>
                </Column>
                <Column  header="Actividad">
                    <template #body="slotProps">
                        {{slotProps.data.activity.name}}
                    </template>
                </Column>
                <Column field="goal" header="META"></Column>
                <Column  header="ACCIONES">
                    <template #body="slotProps">
                        <buttonp label="" class="ml-1 is-red p-button-sm" icon="pi pi-trash" @click="deleteGaol(slotProps.data.activity_type.activity_type_id, slotProps.data.activity.activity_id)" autofocus />
                    </template>
                </Column>

            </DataTable>
        </span>
        <span v-else>
            <h5 class="text-black">Ingrese detalle de metas por actividad mensual</h5> 
        </span>

        <buttonp class="w-6 mt-5 p-button-warning" label="AGREGAR CUMPLIMIENTO" @click.prevent="create_compliance()"/>


        <br /><br /><br /><br /><br /><br />


         <Dialog :header="'Agregar Detalle Cumplimiento'" v-model:visible="getModalAddDetail" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '30vw'}" :closable="false" >
            <div class="p-grid p-nogutter p-d-flex p-justify-center p-ai-center" style="height: 100%; min-height: 20vh">
                <Dropdown id="activity_type" v-model="selectedActivityType" :options="getActivityTypes" :filter="false" placeholder="Seleccione tipo actividad" :showClear="true">
                    <template #value="slotProps">
                        <div class="p-dropdown-supervisors-value" v-if="slotProps.value">
                            <span>
                                {{slotProps.value.name}}
                            </span>
                        </div>
                        <span v-else>
                              {{slotProps.placeholder}}
                        </span>
                    </template>
                    <template #option="slotProps">
                      <div class="p-dropdown-supervisors-option">
                        <span>
                            {{slotProps.option.name}}
                          </span>
                      </div>
                    </template>
                </Dropdown>
                <buttonp  v-if="!selectedActivityType" class="w-6 mt-5 p-button-warning" label="+" @click.prevent="open_modal_agregar_tipo_act()"/>
               

                <span v-if="selectedActivityType && activities.length > 0"> 
                    <Dropdown id="activity_type" v-model="selectedActivity" :options="activities" :filter="false" placeholder="Seleccione  Actividad" :showClear="true">
                          <template #value="slotProps">
                              <div class="p-dropdown-supervisors-value" v-if="slotProps.value">
                                  <span>
                                    {{slotProps.value.name}}
                                  </span>
                              </div>
                              <span v-else>
                                  {{slotProps.placeholder}}
                              </span>
                          </template>
                          <template #option="slotProps">
                          <div class="p-dropdown-supervisors-option">
                            <span>
                                  {{slotProps.option.name}}</span>
                          </div>
                        </template>
                    </Dropdown>
                </span>

                <buttonp  v-if="selectedActivityType && !selectedActivity" class="w-6 mt-5 p-button-warning" label="+" @click.prevent="open_modal_agregar_act()"/>

                <span v-if="selectedActivity"> 
                    <InputText id="meta" type="name" v-model="goal" placeholder="Ingrese Meta"/>
                </span>
                
                <span v-if="goal && selectedActivityType && selectedActivity">
                    <buttonp label="Agregar Meta Actividad" icon="pi pi-save" @click="add_activity_goal();" autofocus />
                </span>
            </div>
            <template #footer>
                <buttonp label="Cerrar" icon="pi pi-times" @click="modal_add_detail = false;" autofocus />
            </template>
        </Dialog>

        <addactivitytype-dialog @refresh="getAllActivitiesType"></addactivitytype-dialog>
        <addactivity-dialog v-if="selectedActivityType != null" @refresh="getAllActivities"></addactivity-dialog>
    </div>
</template>


<script>
    import {mapGetters} from 'vuex';
    import ContractService from '@/service/ContractService'
    import ActivityService from '@/service/ActivityService'
    import EmployeeService from '@/service/EmployeeService'
     import AddactivitytypeDialog from '@/include/AddActivityTypeDialog'
    import AddactivityDialog from '@/include/AddActivityDialog'

    let CreateCompliacePeople = {};
    (function () {
        this.name = 'selectcontract-page';

        this.created = function () {
            this.getAllContracts();
            this.getAllEmployee();
            this.getAllActivitiesType();
        };

        this.mounted  = function () {
         
        };

        this.data = function () {
            return {
                contracts: [],
                selectedContract: null,
                compliance_detail: [],
                selectedMonth: null,
                monthNames: [{id:1, name: 'Enero'}, {id:2, name: 'Febrero'}, {id:3, name: 'Marzo'}, {id:4, name: 'Abril'}, {id:5, name: 'Mayo'}, {id:6, name: 'Junio'}, {id:7, name: 'Julio'}, {id:8, name: 'Agosto'}, {id:9, name: 'Septiembre'}, {id:10, name: 'Octubre'}, {id:11, name: 'Noviembre'}, {id:12, name: 'Deciembre'}],
                years:[2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027],
                selectYear: null,
                selectedAsignedEmployee: null,
                filteredPeople: [],
                allEmployees: [],
                modal_add_detail: false,
                activityTypes: [],
                selectedActivityType: null,
                activities: [],
                selectedActivity: null,
                goal: null,
                adding: false,
                count_detail: 1,
            }
        };
        this.methods = {
            open_modal_agregar_act(){
                this.$store.dispatch('modals/set-act', true);
            },
            open_modal_agregar_tipo_act(){
                this.$store.dispatch('modals/set-tipoact', true);
            },
            getIndexActGoal(act){
                return this.compliance_detail.indexOf(act);
            },
            getActivityGoal(type_id, act_id){
               return  this.compliance_detail.find(item => item.activity_type.activity_type_id === type_id && item.activity.activity_id === act_id);
            },
            deleteGaol(activity_type_id, activity_id){
                console.log(activity_type_id);
                console.log(activity_id);
                var act_goal = this.getActivityGoal(activity_type_id, activity_id);
                console.log(act_goal);
                if(act_goal != null){
                    var index = this.getIndexActGoal(act_goal);
                    console.log(index);
                    if(index > -1){
                        console.log('slice');
                        console.log(this.compliance_detail.length);
                        this.compliance_detail.splice(index, 1);
                        console.log(this.compliance_detail.length);

                    }
                }
            },
            getAllActivities(){
                this.activities = [];
                if(this.selectedActivityType == null || this.selectedContract == null){
                    return;
                }else{
                    this.$store.dispatch('form/submit', true);
                    ActivityService.getActivityByTypeContract(this.selectedActivityType.activity_type_id).then((response) => {
                        this.activities = response.data;
                        this.$store.dispatch('form/submit', false);
                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch('form/submit', false);
                    });
                }
            },
            getAllActivitiesType(){
                this.$store.dispatch('form/submit', true);
                ActivityService.getActivityTypeByEmployee(this.username).then((response) => {
                    this.activityTypes = response.data;
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            add_activity_goal(){
                this.adding = true;
                if(this.selectedActivity != null && this.selectedActivityType != null){
                    var g = this.getActivityGoal(this.selectedActivityType.activity_type_id, this.selectedActivity.activity_id)
                    if(g != null){
                        this.$store.dispatch('form/result', {isError: true, message: 'Ya Agrego Actividad a las metas'});
                        return;
                    }
                }
                let new_act_goal = {'id': this.count_detail,'activity_type': this.selectedActivityType , 'activity': this.selectedActivity , 'goal': parseInt(this.goal)};
                //this.selectedActivityType = null;
                this.selectedActivity = null;
                this.goal = null;
                this.compliance_detail.push(new_act_goal);
                this.count_detail = this.count_detail + 1;
                this.modal_add_detail = false;
                this.modal_add_detail = true;
                this.adding = false;
            },
            add_detail_compliance(){
                if(this.selectedContract){
                    this.modal_add_detail = true;
                }else{
                    this.$store.dispatch('form/result', {isError: true, message: 'Debe seleccionar contrato para ingresar detalle'});
                }
            },
            getAllEmployee(){
                 EmployeeService.getAll().then((response) => {
                    this.allEmployees= response.data;
                    this.filteredEmployee = response.data;
                    for (var i = 0; i < this.allEmployees.length; i++) {
                        this.allEmployees[i]['first_name1'] = this.allEmployees[i]['first_name1'] +" "+this.allEmployees[i]['first_name2']+' '+this.allEmployees[i]['last_name1'] + ' ' +this.allEmployees[i]['last_name2']+'('+this.allEmployees[i]['card_id'] +'-'+ this.allEmployees[i]['check_digit']+')';
                    }
                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch('form/submit', false);
                    });
            },
            create_compliance(){
                 
                 if(this.selectedAsignedEmployee == null){
                    this.$store.dispatch('form/result', {isError: true, message: 'Seleccione Colaborador'});
                    return;
                 }

                 if(this.selectedMonth == null){
                    this.$store.dispatch('form/result', {isError: true, message: 'Seleccione Mes'});
                    return;
                 }

                 if(this.selectYear == null){
                    this.$store.dispatch('form/result', {isError: true, message: 'Seleccione Año'});
                    return;
                 }

                 if(this.selectedContract == null){
                    this.$store.dispatch('form/result', {isError: true, message: 'Seleccione Contrato'});
                    return;
                 }
                  if(this.compliance_detail.length == 0){
                    this.$store.dispatch('form/result', {isError: true, message: 'Ingrese detalle cumplimiento'});
                    return;
                 }

                 this.$store.dispatch('form/submit', true);
                ActivityService.createGoalsMonth(this.selectedAsignedEmployee.employee_id, this.selectedMonth.id, this.selectYear, this.selectedContract.contract_id, this.compliance_detail).then((response) => {
                    this.compliance_detail = [];
                    this.$store.dispatch('form/result', {isError: false, message: response.data.message});
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            searchPeople(event){
                var filteredResult = [];
                if (event.query !== null && event.query.trim() !== '' && event.query.length > 0) {
                    event.query = event.query.toLowerCase();
                    for (var i = 0; i < this.allEmployees.length; i++) {
                        if (this.allEmployees[i].first_name1.toLowerCase().replace(/\s/g, '').trim().includes(event.query.replace(/\s/g, '').trim()) ||
                          this.allEmployees[i].last_name1.toLowerCase().replace(/\s/g, '').trim().includes(event.query.replace(/\s/g, '').trim()) 
                        ) {
                            filteredResult.push(this.allEmployees[i])
                        }
                    }
                    this.filteredPeople = filteredResult;
                } else {
                    return [];
                }   
            },
            getAllContracts(){
                this.$store.dispatch('form/submit', true);
                ContractService.getByEmployee(this.username).then((response) => {
                    this.contracts = response.data;
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
        };
        this.computed = {
            getActivityTypes(){
                return this.activityTypes;
            },
            getModalAddDetail(){
                return this.modal_add_detail;
            },
            getActivitiesNow(){
                return this.activities;
            },
            ...mapGetters({
                username: 'auth/user',
                contract: 'contract/contract'
            }),
        };
        this.components = {AddactivitytypeDialog, AddactivityDialog};
        this.watch = {
            selectedActivityType() {
                if(this.adding == false){
                    this.goal = null;
                    this.selectedActivity = null;
                    this.getAllActivities();
                }
                
            }
        };
        this.props = [];
    }).apply(CreateCompliacePeople);

    export default CreateCompliacePeople
</script>
