<template id="home">
    <div class="card p-fluid">
        <h5 class="text-black">Consulta</h5>
        <div class="grid">
            <div class="col-12 md:col-6 lg:col-4">
                <buttonp class="w-6 mt-3 p-button-warning" label="Por Colaborador" @click.prevent="goTo('compliance-activity')"/>
            </div>
            <div class="col-12 md:col-6 lg:col-4">
                <buttonp class="w-6 mt-3 p-button-warning" label="Calendario Mis Actividades" @click.prevent="goTo('calendar-activity')"/>
            </div>
            <div class="col-12 md:col-6 lg:col-4">
                <buttonp class="w-6 mt-3 p-button-warning" label="Actividades Calendarizadas" @click.prevent="goTo('calendar-by-colaborator')"/>
            </div>
            <div class="col-12 md:col-6 lg:col-4">
                <buttonp class="w-6 mt-3 p-button-warning" label="Calendario de actividades realizadas" @click.prevent="goTo('calendar-colaborator-did')"/>
            </div>
        </div>

        <h5 class="text-black">Gestion</h5>
        <div class="grid">
            <div class="col-12 md:col-6 lg:col-4">
                <buttonp class="w-6 mt-3 p-button-warning" label="Gestion General" @click.prevent="goTo('admin-activity')"/>
            </div>
            <div class="col-12 md:col-6 lg:col-4">
                <buttonp class="w-6 mt-3 p-button-warning" label="Agregar Cumplimeinto" @click.prevent="goTo('create-compliance-activity')"/>
            </div>
        </div>
    </div>
</template>

<script>
    
    import {mapGetters} from 'vuex';
    


    let HomePage = {};
    (function () {
        this.name = 'home-page';
        // let _self = null;

        this.created = function () {

        };

        this.mounted  = function () {
            window.scrollTo(0, 0);
        };

        this.data = function () {
            return {
               
            }
        };
        this.methods = {
            isInGroups: function (groupName) {
                for (var i = 0; i < this.groups.length; i++) {
                        if (this.groups[i].name === groupName) {
                            return true;
                        }  
                }
                return false;
            },
            goAdminDocuType(){
                this.$router.push({name: 'admin-docu-type'});
            },
            goAdminDocuDivi(){
                this.$router.push({name: 'admin-all-employee-docu'});
            },
            goAdminDocu: function(){
                 this.$router.push({name: 'admin-documentation', 
                    params: {
                        docu_id: 0
                        }
                    });
            },
            goTo: function (route) {
                this.$router.push({name: route});
            },
        };
        this.computed = {
             ...mapGetters({
                groups: 'auth/groups'
            })
        };
        this.watch = {

        };
        

        this.props = [];
    }).apply(HomePage);

    export default HomePage
</script>
