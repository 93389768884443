<template id="calendarActivity">
        <div >


            <div class="field" v-if="allEmployees.length > 0">
                <autoComplete v-model="selectedAsignedEmployee" field="first_name1" :suggestions="filteredPeople" @complete="searchPeople($event)" placeholder="Seleccione encargado de actividad *"></autoComplete>
            </div>

             <buttonp class="w-6 m-4 p-button-warning p-button-sm" label="Buscar Actividades" @click.prevent="getAllActivitiesUser()"/>


            <div v-if="getactivitiesCalendar.length > 0">
                <TabView class="mt-5" style="width: : 100vw !important;">
                    <TabPanel header="Calendario Actividades Calendarizadas">
                            <div v-if="getactivitiesCalendar.length > 0" style="z-index: -10 !important;">
                                <FullCalendar :events="getactivitiesCalendar" :options="optionsCalendar"/>
                                <br /><br /><br /><br /><br />
                            </div>
                            <div v-else>
                                <h2>Sin Actividades</h2>
                            </div>
                            
                    </TabPanel>
                    <TabPanel header="Tabla Todas Actividades">
                        <DataTable id="tableActivity" :value="allActivities" responsiveLayout="scroll">
                            <Column field="activity_implementation_id" header="ID"></Column>
                            <Column field="activity.name" header="Actividad"></Column>
                            <Column field="activity.activity_type.name" header="Tipo Actividad"></Column>
                            <Column field="status" header="ESTADO">
                                <template #body="slotProps">
                                    <status-tag :status="slotProps.data.status" :value="''"></status-tag>
                                </template>
                            </Column>
                             <Column header="Calenderiazda Comienzo">
                                <template #body="slotProps">
                                 <span v-if="slotProps.data.schedule_start">{{utcToLocal(slotProps.data.schedule_start)}}</span>
                                 </template>
                             </Column>
                            <Column header="CalendariazdTermino">
                                <template #body="slotProps">
                                <span v-if="slotProps.data.schedule_finish">
                                    {{utcToLocal(slotProps.data.schedule_finish)}}
                                </span>
                                </template>
                            </Column>
                            <Column header="Comienzo">
                                <template #body="slotProps">
                                <span v-if="slotProps.data.activity_start">
                                    {{utcToLocal(slotProps.data.activity_start)}}
                                </span>
                                </template>
                            </Column>
                            <Column header="Termino">
                                <template #body="slotProps">
                                    <span v-if="slotProps.data.activity_finish">
                                        {{utcToLocal(slotProps.data.activity_finish)}}
                                    </span>
                                </template>
                            </Column>
                            
                            <!--Column  header="Acciones"></Column-->
                        </DataTable>
                        <br /><br /><br /><br /><br />
                    </TabPanel>
                </TabView>
            </div>
            <div v-else>
                <h2>Sin Actividades</h2>
            </div>
        </div>
</template>


<script>
    import {mapGetters} from 'vuex';
    import ActivityService from '@/service/ActivityService';
    import '@fullcalendar/core/vdom';
    import dayGridPlugin from '@fullcalendar/daygrid';
    import timeGridPlugin from '@fullcalendar/timegrid';
    import interactionPlugin from '@fullcalendar/interaction';
    import esLocale from '@fullcalendar/core/locales/es';
    import moment from "moment";
    import EmployeeService from '@/service/EmployeeService'
    import StatusTag from '@/include/StatusTag'

    let CalendarByColaborator = {};
    (function () {
        this.name = 'calendar-by-colaborator';

        this.created = function () {
            //this.getAllActivitiesUser();
            this.getAllEmployee();
        };

        this.mounted  = function () {

        };

        this.data = function () {
            return {
                selectedAsignedEmployee: null,
                allEmployees: [],
                filteredPeople: [],
                name: '',
                optionsCalendar: {
                    height: '800px',
                    plugins:[dayGridPlugin, timeGridPlugin, interactionPlugin],
                    initialDate : new Date(),
                    headerToolbar: {
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth,timeGridWeek,timeGridDay'
                    },
                    selectMirror: true, 
                    dayMaxEvents: true,
                    selectable: true,
                    editable: false,
                    locale: esLocale,
                    eventClick: (e) =>  {
                        //handle date click
                        this.editActivity(e.event._def.publicId);
                    }
                },
                activitiesCalendar: [],
                allActivities: [],
            }
        };
        this.methods = {
            searchPeople(event){
                var filteredResult = [];
                if (event.query !== null && event.query.trim() !== '' && event.query.length > 0) {
                    event.query = event.query.toLowerCase();
                    for (var i = 0; i < this.allEmployees.length; i++) {
                        if (this.allEmployees[i].first_name1.toLowerCase().replace(/\s/g, '').trim().includes(event.query.replace(/\s/g, '').trim()) ||
                          this.allEmployees[i].last_name1.toLowerCase().replace(/\s/g, '').trim().includes(event.query.replace(/\s/g, '').trim()) 
                        ) {
                            filteredResult.push(this.allEmployees[i])
                        }
                    }
                    this.filteredPeople = filteredResult;
                } else {
                    return [];
                }   
            },
            getAllEmployee(){
                 EmployeeService.getAll().then((response) => {
                    this.allEmployees= response.data;
                    this.filteredEmployee = response.data;
                    for (var i = 0; i < this.allEmployees.length; i++) {
                        this.allEmployees[i]['first_name1'] = this.allEmployees[i]['first_name1'] +" "+this.allEmployees[i]['first_name2']+' '+this.allEmployees[i]['last_name1'] + ' ' +this.allEmployees[i]['last_name2']+'('+this.allEmployees[i]['card_id'] +'-'+ this.allEmployees[i]['check_digit']+')';
                    }
                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch('form/submit', false);
                    });
            },
             utcToLocal(date){
                if(date){
                  var stillUtc = moment.utc(date).toDate();
                  var local = moment(stillUtc).local().format('DD-MM-YYYY HH:mm');
                  return local;
                }
              },
               utcToLocalCalendar(date){
                if(date){
                  var stillUtc = moment.utc(date).toDate();
                  var local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm');
                  return local;
                }
              },
            editActivity(){
                console.log('go activity implementation');
            },
            getAllActivitiesUser() {




                if(this.selectedAsignedEmployee == null){
                    this.$store.dispatch('form/result', {isError: true, message: 'Seleccione Colaborador'});
                    return;
                 }

                 
                this.$store.dispatch('form/submit', true)
                this.allActivities = [];
                this.activitiesCalendar = [];
                let result = {isError: false, message: null}
                ActivityService.allActiImplByEmployee(this.selectedAsignedEmployee.employee_id)
                    .then(response => {
                         this.allActivities = response.data;
                    if(response.data.length === 0){
                        result.isError = true;
                        result.message = 'Sin resultados';
                        this.$store.dispatch('form/result', result);
                    }

                    for (var i = 0; i < this.allActivities.length; i++) {
                            var titlleeee = this.allActivities[i].activity.name + '('+this.allActivities[i].activity.activity_type.name+')';
                            let evetTemp = {
                                "id": this.allActivities[i].activity_implementation_id,
                                "title": titlleeee,
                                "start": this.utcToLocalCalendar(this.allActivities[i].schedule_start),
                                "end": this.utcToLocalCalendar(this.allActivities[i].schedule_finish),
                            }
                            this.activitiesCalendar.push(evetTemp);
                        }

                    this.$store.dispatch('form/submit', false);
                    })
                    .catch(err => {
                      console.log(err);
                       this.$store.dispatch('form/submit', false)
                    });
                    
            },
        };
        this.computed = {
            getactivitiesCalendar(){
                return this.activitiesCalendar;
            },
            ...mapGetters({
                username: 'auth/user'
            }),
        };
        this.watch = {

        };
        this.props = [];
        this.components = {StatusTag};
    }).apply(CalendarByColaborator);

    export default CalendarByColaborator
</script>
