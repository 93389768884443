<template>
    <span>
        <Dialog :header="'Agregar Tipo Actividad'" v-model:visible="isVisible" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '30vw'}" :closable="false" >
            <div class="p-grid p-nogutter p-d-flex p-justify-center p-ai-center" style="height: 100%; min-height: 20vh">

                <div class="md-min-width card m-5">
                    <h5>Ingreso Tipo Actividad</h5>

                    <div class="field mt-3">
                        <!--h5 for="name1">Nombre</h5-->
                        <InputText id="name1" type="name" :class="{'p-invalid' : error.name}" v-model="name" placeholder="Nombre tipo"/>
                        <h6 v-if="error.name" id="name1-help" class="p-error">Nombre es obligatorio.</h6>
                    </div>

                    <buttonp class="w-8rem mt-5 p-button-warning" icon="pi pi-save" label="Guardar" @click.prevent="add()"/>
                </div>  
                
            </div>
            <template #footer>
                <buttonp label="Cerrar" icon="pi pi-times" @click="closeModal()" autofocus />
            </template>
        </Dialog>
    </span>
</template>

<script>

    import {mapGetters} from 'vuex';
    import ActivityService from '@/service/ActivityService';   

    let AddActivityTypeDialog = {};
    (function () {

        this.name = 'add-activity-type-dialog';

        this.created = function () {
            
        };
        this.data = function () {
            return {
                 name: null,
                 activity: {name:null},
                 is_visible: false
            }
        };
        this.methods = {
            closeModal(){
                this.$store.dispatch('modals/set-tipoact', false);
            },
             add() {
                this.$store.dispatch('form/reset');
                this.$store.dispatch('form/submit', true)
                this.$store.dispatch('form/error', {
                    name: false
                });
                
                if (this.name === null || this.name.trim() === '') {
                    this.$store.dispatch('form/error', {name: true});
                }
            
                if (this.$store.getters['form/invalid-fields'].length > 0) {
                    this.$store.dispatch('form/submit', false)
                    return
                }

                this.activity.name = this.name;
                ActivityService.addActivityType(this.activity)
                    .then(response => {
                        var isError =  false;
                        if(response.data.status === 'ERROR'){
                            isError = true;
                        }else{
                            this.closeModal();
                            this.$emit("refresh", {});
                            //this.$router.push({name: 'home'});
                        }
                        this.$store.dispatch('form/result', {isError: isError, message: response.data.message});
                        this.$store.dispatch('form/submit', false)

                        
                    })
                    .catch(err => {
                      console.log(err);
                      this.$store.dispatch('form/submit', false)
                    });
                    
            },
        };
        this.mounted = function () {};
        this.components = {}
        this.computed = {
             ...mapGetters({
                username: 'auth/user',
                 error: 'form/error-states',
                isVisible: 'modals/tipo_act'
            }),
        };
        this.watch = {
            
        };
        this.props = [];
    }).apply(AddActivityTypeDialog);
    export default AddActivityTypeDialog;
</script>

<style scoped>

</style>
