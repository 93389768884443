<template id="select-contract">
    <div class="card p-fluid m-5">
        <h5>Administracion de Actividades</h5>

        <div class="field" v-if="allEmployees.length > 0">
            <autoComplete v-model="selectedAsignedEmployee" field="first_name1" :suggestions="filteredPeople" @complete="searchPeople($event)" placeholder="Seleccione encargado de actividad *"></autoComplete>
                    
        </div>

        <Dropdown v-model="selectedContract" :options="contracts" optionLabel="Nombre" :filter="false" placeholder="Seleccione Contrato *" :showClear="true" :disabled="compliance_detail.length > 0">
            <template #value="slotProps">
                  <div class="p-dropdown-supervisors-value" v-if="slotProps.value">
                      <span>
                        {{slotProps.value.name}}
                      </span>
                  </div>
                  <span v-else>
                      {{slotProps.placeholder}}
                  </span>
            </template>
            <template #option="slotProps">
                  <div class="p-dropdown-supervisors-option">
                    <span>
                          {{slotProps.option.name}}</span>
                  </div>
            </template>
        </Dropdown>

        <Dropdown v-model="selectedMonth" :options="monthNames" optionLabel="Nombre" :filter="false" placeholder="Seleccione Mes *" :showClear="true">
            <template #value="slotProps">
                  <div class="p-dropdown-supervisors-value" v-if="slotProps.value">
                      <span>
                        {{slotProps.value.name}}
                      </span>
                  </div>
                  <span v-else>
                      {{slotProps.placeholder}}
                  </span>
            </template>
            <template #option="slotProps">
                  <div class="p-dropdown-supervisors-option">
                    <span>
                          {{slotProps.option.name}}</span>
                  </div>
            </template>
        </Dropdown>

        <Dropdown id="asscewfddsd" v-model="selectYear" :options="years" :filter="false" placeholder="Seleccione año *" :showClear="true">
              <template #value="slotProps">
                  <div class="p-dropdown-supervisors-value" v-if="slotProps.value">
                      <span>
                        {{slotProps.value}}
                      </span>
                  </div>
                  <span v-else>
                      {{slotProps.placeholder}}
                  </span>
              </template>
              <template #option="slotProps">
              <div class="p-dropdown-supervisors-option">
                <span>
                      {{slotProps.option}}</span>
              </div>
            </template>
        </Dropdown>

        <buttonp class="w-6 m-4 p-button-warning p-button-sm" label="Buscar Actividades" @click.prevent="buscarActividades()"/>

        <span v-if="compliance_detail.length > 0">
            <DataTable :value="compliance_detail">
                <Column  header="Tipo Actividad" >
                    <template #body="slotProps">
                        {{slotProps.data.activity.activity_type.name}}
                    </template>
                </Column>
                <Column  header="Actividad">
                    <template #body="slotProps">
                        {{slotProps.data.activity.name}}
                    </template>
                </Column>
                <Column field="goal" header="META"></Column>
                <Column field="goal" header="Realizado">
                    <template #body="slotProps">
                        <status-tag :status="'SCHEDULED'" :value="slotProps.data.programado"></status-tag>
                        <status-tag :status="'IN_CALENDAR'" :value="slotProps.data.calendarizado"></status-tag>
                        <status-tag :status="'FINISH'" :value="slotProps.data.finalizado"></status-tag>
                        <status-tag :status="'IN_PROGRESS'" :value="slotProps.data.en_curso"></status-tag>
                        <status-tag :status="'NEVER_START'" :value="slotProps.data.no_ejecutado"></status-tag>
                    </template>
                </Column>
                <Column header="% Complimiento">
                     <template #body="slotProps">
                        {{slotProps.data.complaince}} %
                    </template>
                </Column>
                <Column  header="ACCIONES">
                    <template #body="slotProps">
                        <buttonp label="" class="p-button-sm p-button-grey" icon="pi pi-search" @click="openModEdiActImpl(slotProps.data.detail_act)" autofocus />
                        {{slotProps.data.activity_type}}
                    </template>
                </Column>
            </DataTable>
        </span>
        <span v-else>
            <h5 class="text-black"> actividad mensual</h5> 
        </span>

        <!--buttonp class="w-6 mt-5 p-button-warning" label="CREAR CUMPLIMIENTO" @click.prevent="create_compliance()"/-->


        <br /><br /><br /><br /><br /><br />


         <Dialog :header="'Agregar Meta'" v-model:visible="getModalAddDetail" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '30vw'}" :closable="false" >
            <div class="p-grid p-nogutter p-d-flex p-justify-center p-ai-center" style="height: 100%; min-height: 20vh">
                <Dropdown id="activity_type" v-model="selectedActivityType" :options="activityTypes" :filter="false" placeholder="Seleccione tipo actividad" :showClear="true">
                    <template #value="slotProps">
                        <div class="p-dropdown-supervisors-value" v-if="slotProps.value">
                            <span>
                                {{slotProps.value.name}}
                            </span>
                        </div>
                        <span v-else>
                              {{slotProps.placeholder}}
                        </span>
                    </template>
                    <template #option="slotProps">
                      <div class="p-dropdown-supervisors-option">
                        <span>
                            {{slotProps.option.name}}
                          </span>
                      </div>
                    </template>
                </Dropdown>
                <buttonp  v-if="!selectedActivityType" class="w-6 mt-5 p-button-warning" label="+" @click.prevent="open_modal_agregar_tipo_act()"/>


                <span v-if="selectedActivityType && activities.length > 0"> 
                    <Dropdown id="activity_type" v-model="selectedActivity" :options="activities" :filter="false" placeholder="Seleccione  Actividad" :showClear="true">
                          <template #value="slotProps">
                              <div class="p-dropdown-supervisors-value" v-if="slotProps.value">
                                  <span>
                                    {{slotProps.value.name}}
                                  </span>
                              </div>
                              <span v-else>
                                  {{slotProps.placeholder}}
                              </span>
                          </template>
                          <template #option="slotProps">
                          <div class="p-dropdown-supervisors-option">
                            <span>
                                  {{slotProps.option.name}}</span>
                          </div>
                        </template>
                    </Dropdown>
                </span>
                <buttonp  v-if="!selectedActivity" class="w-6 mt-5 p-button-warning" label="+" @click.prevent="open_modal_agregar_act()"/>

                <span v-if="selectedActivity"> 
                    <InputText id="meta" type="name" v-model="goal" placeholder="Ingrese Meta"/>
                </span>
                
                <span v-if="goal">
                    <buttonp label="Agregar Meta Actividad" icon="pi pi-save" @click="add_activity_goal();" autofocus />
                </span>
            </div>
            <template #footer>
                <buttonp label="Cerrar" icon="pi pi-times" @click="modal_add_detail = false;" autofocus />
            </template>
        </Dialog>

        <Dialog v-if="detail_selected.length > 0" :header="'Tipo:' + detail_selected[0].activity.activity_type.name + '     Actividad: ' + detail_selected[0].activity.name" v-model:visible="getModalDetailAct" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '95vw'}" :closable="false" >
            <div class="p-grid p-nogutter p-d-flex p-justify-center p-ai-center" style="height: 100%; min-height: 20vh">
                <DataTable :value="detail_selected">
                    <Column field="activity_implementation_id" header="ID"></Column>
                    <!--Column  header="Tipo Actividad" >
                        <template #body="slotProps">
                            {{slotProps.data.activity.activity_type.name}}
                        </template>
                    </Column-->
                    <!--Column  header="Actividad">
                        <template #body="slotProps">
                            {{slotProps.data.activity.name}}
                        </template>
                    </Column-->
                    <Column field="schedule_start" header="Comenza - Termina">
                        <template #body="slotProps">
                            <span v-if="!slotProps.data.schedule_start">
                                Sin fecha
                            </span>
                            <span v-else>{{getLocalDate(slotProps.data.schedule_start)}}<br />
                              {{getLocalDate(slotProps.data.schedule_finish)}}
                            </span>
                        </template>
                    </Column>  
                    <!--Column field="schedule_finish" header="Debe Terminar">
                        <template #body="slotProps">
                            <span v-if="!slotProps.data.schedule_finish">
                                <buttonp label="Calendarizar" class="p-button-sm p-button-info" icon="pi pi-pencil" autofocus  @click="calenderizarImple(slotProps.data.activity_implementation_id)"/>
                            </span>
                            <span v-else>{{slotProps.data.schedule_finish}}</span>
                        </template>
                    </Column-->
                    <Column field="status" header="ESTADO">
                        <template #body="slotProps">
                            <status-tag :status="slotProps.data.status" :value="''"></status-tag>
                        </template>
                    </Column>
                    <Column header="Comenzo">
                        <template #body="slotProps">

                            <span v-if="!slotProps.data.activity_start">
                                Sin Fecha
                            </span>
                            <span v-else>{{getLocalDate(slotProps.data.activity_start)}}</span>
                        </template>
                    </Column>
                    <Column header="Participantes">
                        <template #body="slotProps">
                          <span v-if="slotProps.data.vehicles != null && slotProps.data.employees != null">
                             <buttonp :label="slotProps.data.vehicles.length + 'Vehiculos / ' +  slotProps.data.employees.length.toString() + 'Personas'" class="p-button-text p-button-plain"  autofocus  @click="aditPeopleVehicle(slotProps.data)" disabled/>
                          </span> 
                        </template>
                    </Column>
                     <Column header="Comprobante">
                        <template #body="slotProps">
                            <span v-if="slotProps.data.url_file != null">
                                <buttonp label="Ver" class="p-button-text p-button-help" icon="pi pi-file" autofocus  @click="editfile(slotProps.data.activity_implementation_id)" disabled/>
                            </span>
                            <span v-else>
                                <buttonp label="Sin Archivo" class="p-button-text p-button-plain" icon="pi pi-file" autofocus  @click="editfile(slotProps.data.activity_implementation_id)" disabled/>
                            </span>
                        </template>
                    </Column>
                    <Column header="Asistencia">
                        <template #body="slotProps">
                            <span v-if="!slotProps.data.activity_finish">
                                <buttonp label="Certifico/Finalizo" class="p-button-text p-button-danger" icon="pi pi-flag-fill" autofocus  @click="finalizarImple(slotProps.data.activity_implementation_id)" disabled/>
                            </span>
                            <span v-else>{{getLocalDate(slotProps.data.activity_finish)}}</span>
                        </template>
                    </Column>
                </DataTable>
            </div>
            <template #footer>
                <buttonp label="Cerrar" icon="pi pi-times" @click="modal_detail_act = false;" autofocus />
            </template>
        </Dialog>

        <editactivityimpl-dialog v-if="selectedImplementation_id !==null && editactivityimpl" :id="selectedImplementation_id"></editactivityimpl-dialog>

        <incalendar-dialog v-if="selectedImplementation_id!==null && incalendar" :id="selectedImplementation_id"></incalendar-dialog>

        <startactivity-dialog v-if="selectedImplementation_id!==null && startactivity" :id="selectedImplementation_id"></startactivity-dialog>

        <finishactivity-dialog v-if="selectedImplementation_id!==null && finishactivity" :id="selectedImplementation_id"></finishactivity-dialog>

        <uploadfile-dialog v-if="selectedImplementation_id!==null && uploadfile" :id="selectedImplementation_id"></uploadfile-dialog>

        <span v-if="getReload_dialog">
           <addpeoplevehicle-dialog v-if="selectedImplementation_id !== null  && addpeoplevehicle" :id="selectedImplementation_id" :vehicles="selectedVehicles" :employees="selectedEmployees" @refresh="buscarActividades()" :only_read="true"></addpeoplevehicle-dialog>
        </span>
       

    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import moment from "moment";
    import ContractService from '@/service/ContractService'
    import ActivityService from '@/service/ActivityService'
    import EmployeeService from '@/service/EmployeeService'
    import AddactivitytypeDialog from '@/include/AddActivityTypeDialog'
    import AddactivityDialog from '@/include/AddActivityDialog'
    import EditactivityimplDialog from '@/include/EditActImplDialog'
    import StatusTag from '@/include/StatusTag'
    import IncalendarDialog from '@/include/editactimple/IncalendarDialog'
    import StartactivityDialog from '@/include/editactimple/StartactivityDialog'
    import FinishactivityDialog from '@/include/editactimple/FinishactivityDialog'
    import UploadfileDialog from '@/include/editactimple/UploadfileDialog'
    import AddpeoplevehicleDialog from '@/include/editactimple/AddpeoplevehicleDialog'

    let AdminActivity = {};
    (function () {
        this.name = 'adminactivity-page';

        this.created = function () {
            this.$store.dispatch('modals/set-startact', false);
            this.$store.dispatch('modals/set-calendaract', false);
            this.$store.dispatch('modals/set-actimpl', false);
            this.$store.dispatch('modals/set-actimpl', false);
            this.$store.dispatch('modals/set-finishact', false);
            this.$store.dispatch('modals/set-act', false);
            this.$store.dispatch('modals/set-tipoact', false);
            this.getAllContracts();
            this.getAllEmployee();
            this.getAllActivitiesType();
        };

        this.mounted  = function () {
         
        };

        this.data = function () {
            return {
                contracts: [],
                selectedContract: null,
                compliance_detail: [],
                selectedMonth: null,
                years:[2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027],
                selectYear: null,
                monthNames: [{id:1, name: 'Enero'}, {id:2, name: 'Febrero'}, {id:3, name: 'Marzo'}, {id:4, name: 'Abril'}, {id:5, name: 'Mayo'}, {id:6, name: 'Junio'}, {id:7, name: 'Julio'}, {id:8, name: 'Agosto'}, {id:9, name: 'Septiembre'}, {id:10, name: 'Octubre'}, {id:11, name: 'Noviembre'}, {id:12, name: 'Deciembre'}],
                selectedAsignedEmployee: null,
                filteredPeople: [],
                allEmployees: [],
                modal_add_detail: false,
                activityTypes: [],
                selectedActivityType: null,
                activities: [],
                selectedActivity: null,
                goal: null,
                adding: false,
                count_detail: 1,
                detail_selected: [],
                modal_detail_act: false,
                selectedImplementation_id: null,
                editactivityimpl: false,
                incalendar: false,
                startactivity: false,
                finishactivity: false,
                uploadfile: false,
                addpeoplevehicle: false,
                selectedVehicles: [],
                selectedEmployees: [],
                reload_dialog: false,
            }
        };
        this.methods = {
           getLocalDate(date){
                if(date){
                  var stillUtc = moment.utc(date).toDate();
                  var local = moment(stillUtc).local().format('YYYY-MM-DD  h:mm:ss');
                  return local;
                }
            },
            aditPeopleVehicle(imp){
              this.$store.dispatch('modals/set-peoplevehicleact', false);
              this.reload_dialog = false;
                this.addpeoplevehicle = true;
                this.selectedEmployees = imp.employees;
                this.selectedVehicles = imp.vehicles;
                this.selectedImplementation_id = imp.activity_implementation_id;
                this.$store.dispatch('modals/set-peoplevehicleact', true);
                this.reload_dialog = true;
            },
            editfile(id){
                this.uploadfile = true;
                this.selectedImplementation_id = id;
                this.$store.dispatch('modals/set-uploadact', true);
            },
            finalizarImple(id){
                this.finishactivity = true;
                this.selectedImplementation_id = id;
                this.$store.dispatch('modals/set-finishact', true);
            },
            comnzarImple(id){
                this.startactivity = true;
                this.selectedImplementation_id = id;
                this.$store.dispatch('modals/set-startact', true);
            },
            calenderizarImple(id){
                this.incalendar = true;
                this.selectedImplementation_id = id;
                this.$store.dispatch('modals/set-calendaract', true);
            },
            open_modal_edit_act_impl(id){
                this.selectedImplementation_id = id;
                this.$store.dispatch('modals/set-actimpl', true);
            },
            open_modal_agregar_act(){
                this.$store.dispatch('modals/set-act', true);
            },
            open_modal_agregar_tipo_act(){
                this.$store.dispatch('modals/set-tipoact', true);
            },
            buscarActividades(){
               
                if(this.selectedAsignedEmployee == null){
                    this.$store.dispatch('form/result', {isError: true, message: 'Seleccione Colaborador'});
                    return;
                 }

                 if(this.selectedMonth == null){
                    this.$store.dispatch('form/result', {isError: true, message: 'Seleccione Mes'});
                    return;
                 }

                 if(this.selectYear == null){
                    this.$store.dispatch('form/result', {isError: true, message: 'Seleccione Año'});
                    return;
                 }

                 if(this.selectedContract == null){
                    this.$store.dispatch('form/result', {isError: true, message: 'Seleccione Contrato'});
                    return;
                 }

                  this.$store.dispatch('form/submit', true);
                this.detail_selected = []
                this.modal_detail_act = false;

                ActivityService.monthReportEmployee(this.selectedAsignedEmployee.employee_id, this.selectYear, this.selectedMonth, this.selectedContract.contract_id).then((response) => {
                    this.compliance_detail = response.data.data;
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            addEmptyActivityImpl(activity_type_id, activity_id){
                console.log(activity_type_id);
                console.log(activity_id);
            },
            openModEdiActImpl(slected_act){
                this.detail_selected = slected_act
                this.modal_detail_act = true;
            },
            getIndexActGoal(act){
                return this.compliance_detail.indexOf(act);
            },
            getActivityGoal(type_id, act_id){
               return  this.compliance_detail.find(item => item.activity_type.activity_type_id === type_id && item.activity.activity_id === act_id);
            },
            deleteGaol(activity_type_id, activity_id){
                var act_goal = this.getActivityGoal(activity_type_id, activity_id);
                if(act_goal != null){
                    var index = this.getIndexActGoal(act_goal);
                    console.log(index);
                    if(index > -1){
                        console.log('slice');
                        console.log(this.compliance_detail.length);
                        this.compliance_detail.splice(index, 1);
                        console.log(this.compliance_detail.length);

                    }
                }
            },
            getAllActivities(){
                this.activities = [];
                if(this.selectedActivityType == null || this.selectedContract == null){
                    return;
                }else{
                    this.$store.dispatch('form/submit', true);
                    ActivityService.getActivityByTypeContract(this.selectedActivityType.activity_type_id).then((response) => {
                        this.activities = response.data;
                        this.$store.dispatch('form/submit', false);
                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch('form/submit', false);
                    });
                }
            },
            getAllActivitiesType(){
                this.$store.dispatch('form/submit', true);
                ActivityService.getActivityTypeByEmployee(this.username).then((response) => {
                    this.activityTypes = response.data;
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            add_activity_goal(){
                let new_act_goal = {'activity_type': this.selectedActivityType , 'activity': this.selectedActivity , 'goal': this.goal, 'employee': this.selectedAsignedEmployee.employee_id, 'contract': this.selectedContract.contract_id};
                this.selectedActivity = null;
                this.goal = null;
                ActivityService.addGoalEmployee(new_act_goal).then((response) => {
                    console.log(response.data);
                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch('form/submit', false);
                    });
            },
            add_detail_compliance(actT, act){
                if(this.selectedContract){
                    this.modal_add_detail = true;
                    this.selectedActivity = act;
                    this.selectedActivityType = actT;
                }else{
                    this.$store.dispatch('form/result', {isError: true, message: 'Debe seleccionar contrato para ingresar detalle'});
                }
            },
            getAllEmployee(){
                 EmployeeService.getAll().then((response) => {
                    this.allEmployees= response.data;
                    this.filteredEmployee = response.data;
                    for (var i = 0; i < this.allEmployees.length; i++) {
                        this.allEmployees[i]['first_name1'] = this.allEmployees[i]['first_name1'] +" "+this.allEmployees[i]['first_name2']+' '+this.allEmployees[i]['last_name1'] + ' ' +this.allEmployees[i]['last_name2']+'('+this.allEmployees[i]['card_id'] +'-'+ this.allEmployees[i]['check_digit']+')';
                    }
                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch('form/submit', false);
                    });
            },
            create_compliancegoTo(){
                // se crean registros vacios de 
            },
            searchPeople(event){
                var filteredResult = [];
                if (event.query !== null && event.query.trim() !== '' && event.query.length > 0) {
                    event.query = event.query.toLowerCase();
                    for (var i = 0; i < this.allEmployees.length; i++) {
                        if (this.allEmployees[i].first_name1.toLowerCase().replace(/\s/g, '').trim().includes(event.query.replace(/\s/g, '').trim()) ||
                          this.allEmployees[i].last_name1.toLowerCase().replace(/\s/g, '').trim().includes(event.query.replace(/\s/g, '').trim()) 
                        ) {
                            filteredResult.push(this.allEmployees[i])
                        }
                    }
                    this.filteredPeople = filteredResult;
                } else {
                    return [];
                }   
            },
            getAllContracts(){
                this.$store.dispatch('form/submit', true);
                ContractService.getByEmployee(this.username).then((response) => {
                    this.contracts = response.data;
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
        };
        this.computed = {
          getReload_dialog(){
            return this.reload_dialog;
          },
            getModalDetailAct(){
                return this.modal_detail_act;
            },
            getModalAddDetail(){
                return this.modal_add_detail;
            },
            getActivitiesNow(){
                return this.activities;
            },
            ...mapGetters({
                username: 'auth/user',
                contract: 'contract/contract'
            }),
        };
        this.components = {AddactivitytypeDialog, AddactivityDialog, EditactivityimplDialog, StatusTag, IncalendarDialog, StartactivityDialog, FinishactivityDialog, UploadfileDialog, AddpeoplevehicleDialog};
        this.watch = {
            selectedActivityType() {
                if(this.adding == false){
                    this.goal = null;
                    this.selectedActivity = null;
                    this.getAllActivities();
                }
                
            }
        };
        this.props = [];
    }).apply(AdminActivity);

    export default AdminActivity
</script>
