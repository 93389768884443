const state = () => ({
	tipo_act: localStorage.getItem('modal_tipo_act'),
    act: localStorage.getItem('modal_act'),
    act_impl: localStorage.getItem('act_impl'),
    calendar_act: localStorage.getItem('calendar_act'),
    people_vehicle_act: localStorage.getItem('people_vehicle_act'),
    finish_act: localStorage.getItem('finish_act'),
    start_act: localStorage.getItem('start_act'),
    upload_act: localStorage.getItem('upload_act')
});
 
const getters = {
	tipo_act(state) {
        return state.tipo_act;
    },
    act(state) {
        return state.act;
    },
    act_impl(state) {
        return state.act_impl;
    },
    calendar_act(state) {
        return state.calendar_act;
    },
    people_vehicle_act(state) {
        return state.people_vehicle_act;
    },
    finish_act(state) {
        return state.finish_act;
    },
    start_act(state) {
        return state.start_act;
    },
    upload_act(state) {
        return state.upload_act;
    },
};
 
const actions = {
	'set-tipoact'(context, tipo_act) {
        context.commit('tipo_act', tipo_act);
        localStorage.setItem('tipo_act', tipo_act);
    },
    'set-act'(context, act) {
        context.commit('act', act);
        localStorage.setItem('act', act);
    },
    'set-actimpl'(context, act_impl) {
        context.commit('act_impl', act_impl);
        localStorage.setItem('act_impl', act_impl);
    },
    'set-calendaract'(context, calendar_act) {
        context.commit('calendar_act', calendar_act);
        localStorage.setItem('calendar_act', calendar_act);
    },
    'set-peoplevehicleact'(context, people_vehicle_act) {
        context.commit('people_vehicle_act', people_vehicle_act);
        localStorage.setItem('people_vehicle_act', people_vehicle_act);
    },
    'set-finishact'(context, finish_act) {
        context.commit('finish_act', finish_act);
        localStorage.setItem('finish_act', finish_act);
    },
    'set-startact'(context, start_act) {
        context.commit('start_act', start_act);
        localStorage.setItem('start_act', start_act);
    },
    'set-uploadact'(context, upload_act) {
        context.commit('upload_act', upload_act);
        localStorage.setItem('upload_act', upload_act);
    },
};
 
const mutations = {
	tipo_act(state, value) {
		state.tipo_act = value;
	},
	act(state, value) {
		state.act = value;
	},
    act_impl(state, value) {
        state.act_impl = value;
    },
    calendar_act(state, value) {
        state.calendar_act = value;
    },
    people_vehicle_act(state, value) {
        state.people_vehicle_act = value;
    },
    finish_act(state, value) {
        state.finish_act = value;
    },
    start_act(state, value) {
        state.start_act = value;
    },
   upload_act (state, value) {
        state.upload_act = value;
    }
};
 
export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}