import axios from "axios";

export default {


    addParticipants(participants, impl_id){
        const url = `${process.env.VUE_APP_API_ACTIVITY_URL}/addparticipants`;
        let data = {participants: participants, activity_implementation_id: impl_id}
        return axios.post(url, data)
    },

    createGoalsMonth(employee_id, month, year, contract_id, compliance_detail){
        const url = `${process.env.VUE_APP_API_ACTIVITY_URL}/creategoalsmonth`;
        let data = {employee: employee_id, year: year, month: month, contract: contract_id, detail: compliance_detail}
        return axios.post(url, data)
    },

    monthReportEmployee(employee_id, year, month, contract_id){
        const url = `${process.env.VUE_APP_API_ACTIVITY_URL}/monthreportemployee`;
        let data = {employee: employee_id, year: year, month: month.id, contract: contract_id}
        return axios.post(url, data)
    },
    
    getActivityDates(dates) {
        const url = `${process.env.VUE_APP_API_ACTIVITY_URL}/dates`;
        let data = {dates: dates}
        return axios.post(url, data)
    },

    getById(id) {
        const url = `${process.env.VUE_APP_API_ACTIVITY_URL}/actid`;
        let data = {id: id}
        return axios.post(url, data)
    },

    calendarActivity(activity){
        const url = `${process.env.VUE_APP_API_ACTIVITY_URL}/addschedule`;
        return axios.post(url, activity)
    },

    finishActivity(activity){
        const url = `${process.env.VUE_APP_API_ACTIVITY_URL}/finishact`;
        return axios.post(url, activity)
    },

    startActivity(activity){
        const url = `${process.env.VUE_APP_API_ACTIVITY_URL}/startact`;
        return axios.post(url, activity)
    },

    addActivityType(activityType) {
        const url = `${process.env.VUE_APP_API_ACTIVITY_URL}/typeadd`;
        let data = {activity_type: activityType}
        return axios.post(url, data)
    },

    allActiImplByEmployee(id) {
        const url = `${process.env.VUE_APP_API_ACTIVITY_URL}/getallimplemployee`;
        let data = {employee_id: id}
        return axios.post(url, data)
    },

     allActiImplByEmployeeDID(id) {
        const url = `${process.env.VUE_APP_API_ACTIVITY_URL}/getallimplemployeedid`;
        let data = {employee_id: id}
        return axios.post(url, data)
    },

    allActiImplCreator(){
        const url = `${process.env.VUE_APP_API_ACTIVITY_URL}/allmmplementasigned`;
        return axios.get(url)
    },

    newActivityImplementation(act_imple, file) {
        const url = `${process.env.VUE_APP_API_ACTIVITY_URL}/newactivityimplementation`;
        let data = {activity_implementation: act_imple, file:file}
        return axios.post(url, data)
    },

    getActivityTypeByEmployee(){
        const url = `${process.env.VUE_APP_API_ACTIVITY_URL}/type/all`;
        let data = {};
        return axios.post(url, data)
    },

    getActivityByEmployee(){
        const url = `${process.env.VUE_APP_API_ACTIVITY_URL}/getemployee`;
        let data = {};
        return axios.post(url, data)
    },

    addActivity(activity, file) {
        const url = `${process.env.VUE_APP_API_ACTIVITY_URL}/add`;
        let data = {activity: activity, file: file}
        return axios.post(url, data)
    },

    doActivity(activity) {
        const url = `${process.env.VUE_APP_API_ACTIVITY_URL}/do`;
        let data = {activity_imple: activity}
        return axios.post(url, data)    
    },

    getEmployeeByBusiness() {
        const url = `${process.env.VUE_APP_API_CORE_URL}/employees`;
        let data = {operation: 'get-by-business'}
        return axios.post(url, data)
    },

    getEmployeeNotDriver() {
        const url = `${process.env.VUE_APP_API_CORE_URL}/employees`;
        let data = {operation: 'get-all-not-driver'}
        return axios.post(url, data)
    },

    askCompliance(contract, month, year) {
        const url = `${process.env.VUE_APP_API_ACTIVITY_URL}/complainreport`;
        let data = {contract: contract, month: month, year: year}
        return axios.post(url, data)
    },

     updateimg(activity_implementation_id, path) {
        const url = `${process.env.VUE_APP_API_ACTIVITY_URL}/editurlfileimpl`;
        let data = {activity_implementation_id: activity_implementation_id, path: path}
        return axios.post(url, data)
    },

    askMyCompliance(month, year) {
        const url = `${process.env.VUE_APP_API_ACTIVITY_URL}/mycomplainreport`;
        let data = {month: month, year: year}
        return axios.post(url, data)
    },

    getActivityByTypeContract(activity_type_id){
        const url = `${process.env.VUE_APP_API_ACTIVITY_URL}/bytype`;
        let data = {activity_type_id: activity_type_id}
        return axios.post(url, data)
    },

    getAllVehicle() {
        const url = `${process.env.VUE_APP_API_CORE_URL}/vehicles`;
        return axios.get(url)
    },

}
