<template>
    <span>
        <Dialog :header="'Finalizar Actividad'" v-model:visible="is_visible_finish" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '30vw'}" :closable="false" >
            <div class="p-grid p-nogutter p-d-flex p-justify-center p-ai-center" style="height: 100%; min-height: 20vh">

                <div class="md-min-width card m-3">
                    <h5 class="text-black m-3"></h5>
                    
                    <h5 class="text-black">Inicio Actividad</h5>
                    <Calendar id="startActi" v-model="start_act" :showTime="true" />

                    <h5 class="text-black">Finalizar Actividad</h5>
                    <Calendar id="finishActi" v-model="finish_act" :showTime="true" />

                    <br /><br />
                    <buttonp class="w-8rem mt-5 p-button-warning" icon="pi pi-save" label="Certifico Termino" @click.prevent="finishActivity()"/>
                
                </div>
            </div>
            <template #footer>
                <buttonp label="Cerrar" icon="pi pi-times" @click="closeModal();" autofocus />
            </template>
        </Dialog>
    </span>
</template>

<script>

    import {mapGetters} from 'vuex';
    import ActivityService from '@/service/ActivityService';  
    import moment from "moment";

    let FinishactivityDialog = {};
    (function () {

        this.name = 'finishactivity-dialog';

        this.created = function () {
        };
        this.data = function () {
            return {
                name: '',
                activity: {name:null, contract_id: null, is_enable: true, url_file: null, activity_type_id: null},
                contracts: [],
                activitiesType: [],
                selectedActivityType: null,
                selectedContract: null,
                upload_data_file: null,
                start_act: null,
                finish_act: null,
            }
        };
        this.methods = {
            getLocalDate(date){
                if(date){
                  var stillUtc = moment.utc(date).toDate();
                  var local = moment(stillUtc).local().format('YYYY/MM/DD h:mm:ss');
                  return local;
                }
            },
            closeModal(){
                this.$store.dispatch('modals/set-finishact', false);
            },
            loadImplementation(id){
                ActivityService.getById(id)
                    .then(response => {
                        console.log(response.data.activity)
                        var isError =  false;
                        if(response.data.status === 'ERROR'){
                            isError = true;
                        }else{
                            this.activity = response.data
                            if(this.activity.activity_start != null)
                                this.start_act =  this.getLocalDate(this.activity.activity_start);
                            if(this.activity.activity_finish != null)
                                this.finish_act =  this.getLocalDate(this.activity.activity_finish);
                        }
                        this.$store.dispatch('form/result', {isError: isError, message: response.data.message});
                        this.$store.dispatch('form/submit', false);
                    })
                    .catch(err => {
                      console.log(err);
                      this.$store.dispatch('form/submit', false);
                    });
            },
            finishActivity() {
                this.$store.dispatch('form/reset');
                this.$store.dispatch('form/submit', true)
                this.$store.dispatch('form/error', {
                    start: false,
                    finish: false,
                });

                if (this.start_act === null) {
                    this.$store.dispatch('form/error', {start: true});
                }
 
                if (this.finish_act === null) {
                    this.$store.dispatch('form/error', {finish: true});
                }

                if (this.$store.getters['form/invalid-fields'].length > 0) {
                    this.$store.dispatch('form/submit', false)
                    return
                }

                this.activity.activity_start = this.start_act;
                this.activity.activity_finish = this.finish_act;
                ActivityService.finishActivity(this.activity)
                    .then(response => {
                        console.log(5);
                        var isError =  false;
                        if(response.data.status === 'ERROR'){
                            isError = true;
                        }else{
                            this.start_act = null;
                            this.finish_act = null;
                            this.closeModal();
                            this.$emit("refresh", {});
                        }
                        this.$store.dispatch('form/result', {isError: isError, message: response.data.message});
                        this.$store.dispatch('form/submit', false);
                        
                    })
                    .catch(err => {
                      console.log(err);
                      this.$store.dispatch('form/submit', false);
                    });
                    
                    
            },
        };
        this.mounted = function () {
            this.start_act = null;
            this.finish_act = null;
            this.loadImplementation(this.id);
        };
        this.components = {}
        this.computed = {
            getVisibility(){
                if(this.impl_id !== null){
                    console.log("return true");
                    return true
                }
                else{
                    return false
                }
            },
             ...mapGetters({
                username: 'auth/user',
                error: 'form/error-states',
                is_visible_finish: 'modals/finish_act'
            }),
        };
        this.watch = {
            id(){
                this.start_act = null;
                this.finish_act = null;
                this.loadImplementation(this.id);
            }
        };
        this.props = ['id'];
    }).apply(FinishactivityDialog);
    export default FinishactivityDialog;
</script>

<style scoped>

</style>
