<template>
    <span>
        <Dialog :header="'Participantes Actividad'" v-model:visible="is_visible_vp" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '70vw'}" :closable="false" >
            <div class="p-grid p-nogutter p-d-flex p-justify-center p-ai-center" style="height: 100%; min-height: 20vh">
                <div class="md-min-width card m-3">
                    <h5 class="text-black m-3">Selecciones Participantes</h5>

                    <autoComplete v-model="selectedEmployee" field="first_name1" :suggestions="filteredEmployee" @complete="queryEmployee($event)" placeholder="Participante de actividad *"></autoComplete><buttonp v-if="only_read==false" label="" class="p-button-sm p-button-grey" icon="pi pi-plus" @click="pushSelectedEmployees()" autofocus />

                    <autoComplete class="ml-5" v-model="selectedVehicle" field="plate" :suggestions="filteredVehicles" @complete="queryVehicle($event)" placeholder="Vehiculo de actividad *"></autoComplete><buttonp v-if="only_read==false" label="" class="p-button-sm p-button-grey" icon="pi pi-plus" @click="pushSelectedVehicles()" autofocus />
 
						<div class="m-4" v-if="participants.length > 0">

							<DataTable :value="getParticipats" responsiveLayout="scroll">
								<!--Column field="present" header="Asistencia">
									<template #body="slotProps">
										<Checkbox id="binary" v-model="slotProps.data.present" :binary="true" />
									</template>
								</Column-->
								<Column field="name" header="Nombre/Patente"></Column>
								<Column v-if="only_read==false" :exportable="false" style="min-width:8rem">
									<template #body="slotProps">
									<buttonp icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="removeFromParticipant(slotProps.data)" />
									</template>
								</Column>
							</DataTable>
	
						</div>
						<div class="ml-2" v-else>
							<h6 class="p-error">ATENCION!! Debe seleccionar Participante o Vehiculos.</h6>
						</div>
					
				</div>
			</div>
			<template #footer>
                <buttonp v-if="only_read==false" class="w-10rem h-4rem  mt-5 p-button-warning" icon="pi pi-save" label="Guardar Participantes" @click.prevent="save()"/>
					<buttonp label="Cerrar" icon="pi pi-times" @click="closeModal();" autofocus />
			</template>
		</Dialog>
	</span>
</template>

<script>

    import {mapGetters} from 'vuex';
    import ActivityService from '@/service/ActivityService'; 
    import EmployeeService from '@/service/EmployeeService' 

    let AddpeoplevehicleDialog = {};
    (function () {

        this.name = 'addpeoplevehicle-dialog';

        this.created = function () {
            console.log('createtetetetete');
			this.getAllVehicles();
			this.getAllEmployee();
        };
        this.data = function () {
            return {
                name: '',
                activity: {name:null, contract_id: null, is_enable: true, url_file: null, activity_type_id: null},
                contracts: [],
                activitiesType: [],
                selectedActivityType: null,
                selectedContract: null,
                upload_data_file: null,
                start_act: null,
                finish_act: null,
                participants: [],
                visibleFull:false,
                selectedEmployee: [],
                selectedVehicle: [],
                selectedParticipats: [],
                filteredVehicles: [],
                filteredEmployee: [],
            }
        };
        this.methods = {
            openButtonBar(){
                this.visibleFull = true;
            },
            removeFromParticipant(participant){
                    var index = -1;
                    this.selectedParticipats = []
                    for (var i = 0; i < this.participants.length; i++) {
                        if(this.participants[i].id === participant.id){
                            index = i;
                        }
                    }

                    if(index >=0){
                       this.participants.splice(index, 1);
                    }
            },
             getAllEmployee(){
                 EmployeeService.getAll().then((response) => {
                    this.allEmployees= response.data;
                    this.filteredEmployee = response.data;
                    for (var i = 0; i < this.allEmployees.length; i++) {
                        this.allEmployees[i]['first_name1'] = this.allEmployees[i]['first_name1'] +" "+this.allEmployees[i]['first_name2']+' '+this.allEmployees[i]['last_name1'] + ' ' +this.allEmployees[i]['last_name2']+'('+this.allEmployees[i]['card_id'] +'-'+ this.allEmployees[i]['check_digit']+')';
                    }
                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch('form/submit', false);
                    });
            },
            getAllVehicles(){
                this.$store.dispatch('form/submit', true);
                ActivityService.getAllVehicle().then((response) => {
                    this.allVehicles = response.data;
                    this.filteredVehicles = response.data;
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            incluirVehiculo(v){
                var newv = {id: v.vehicle_id, name: v.plate, type:'vehicle', present:false}
                this.participants.push(newv);
            },
            incluirEmployee(e){
                var newem = {id: e.employee_id, name: e.first_name1+' '+e.last_name1+'('+e.card_id +'-'+ e.check_digit+')', type:'employee', present:false}
                this.participants.push(newem);
            },
			pushSelectedEmployees(){
                var newParticipant2 = {id: this.selectedEmployee.employee_id, name: this.selectedEmployee.first_name1, type:'employee', present:false}
                this.participants.push(newParticipant2);
                this.selectedEmployee = null;
                
            },
            pushSelectedVehicles(){
                    var newParticipant = {id: this.selectedVehicle.vehicle_id, name: this.selectedVehicle.plate, type:'vehicle', present:false}
                    this.participants.push(newParticipant);
                    this.selectedVehicle= null;
            },
            closeModal(){
                this.$store.dispatch('modals/set-peoplevehicleact', false);
            },
            loadImplementation(id){
                console.log(id);
                console.log(this.vehicles);
                console.log(this.employees)
                if(this.vehicles != null){
                for (var i = 0; i < this.vehicles.length; i++) {
                    this.incluirVehiculo(this.vehicles[i])
                }    
                }

                if(this.employees != null){
                    for (var ii = 0; ii < this.employees.length; ii++) {
                        this.incluirEmployee(this.employees[ii])
                    }    
                }
            },
            save() {
                this.$store.dispatch('form/reset');
                this.$store.dispatch('form/submit', true)
                this.$store.dispatch('form/error', {
                    start: false,
                    finish: false,
                });
            
                if (this.$store.getters['form/invalid-fields'].length > 0) {
                    this.$store.dispatch('form/submit', false)
                    return
                }

                ActivityService.addParticipants(this.participants, this.id)
                    .then(response => {
                        var isError =  false;
                        if(response.data.status === 'ERROR'){
                            isError = true;
                        }else{
                            this.closeModal();
                            this.$emit("refresh", {});
                        }
                        this.$store.dispatch('form/result', {isError: isError, message: response.data.message});
                        this.$store.dispatch('form/submit', false);
                        
                    })
                    .catch(err => {
                      console.log(err);
                      this.$store.dispatch('form/submit', false);
                    });
                    
            },
            queryVehicle(event) {
                var queryVehicle = event.query.toLowerCase();
                var filteredResultVehicles = [];
                if (queryVehicle !== null && queryVehicle.trim() !== '' && queryVehicle.length > 1) {
                    for (var i = 0; i < this.allVehicles.length; i++) {
                        if (this.allVehicles[i].plate.toLowerCase().replace(/\s/g, '').trim().includes(queryVehicle.replace(/\s/g, '').trim())
                        ) {
                            filteredResultVehicles.push(this.allVehicles[i])
                        }
                    }
                    this.filteredVehicles = filteredResultVehicles;
                } else {
                    this.filteredVehicles = this.allVehicles;
                }
            },
            queryEmployee(event) {
                var queryEmployee = event.query.toLowerCase();
                var filteredResultEmployee = [];
                if (queryEmployee !== null && queryEmployee.trim() !== '' && queryEmployee.length > 1) {
                    for (var i = 0; i < this.allEmployees.length; i++) {
                        if (this.allEmployees[i].first_name1.toLowerCase().replace(/\s/g, '').trim().includes(queryEmployee.replace(/\s/g, '').trim()) ||
                          this.allEmployees[i].last_name1.toLowerCase().replace(/\s/g, '').trim().includes(queryEmployee.replace(/\s/g, '').trim()) 
                        ) {
                            filteredResultEmployee.push(this.allEmployees[i])
                        }
                    }
                    this.filteredEmployee = filteredResultEmployee;
                } else {
                    this.filteredEmployee = this.allEmployees;
                }
            },
            init(){
                this.participants = [];
                if(this.vehicles != null){
                    for (var i = 0; i < this.vehicles.length; i++) {
                        this.incluirVehiculo(this.vehicles[i])
                    }
                } 
                if(this.employees != null){
                    for (var ii = 0; ii < this.employees.length; ii++) {
                        this.incluirEmployee(this.employees[ii])
                    }    
                }
            },
        };
        this.mounted = function () {
            this.loadImplementation(this.id);
        };
        this.components = {}
        this.computed = {
			getParticipats(){
                return this.participants;
            },
            getVehicles(){
                return this.filteredVehicles;
            },
            getEmployee(){
                return this.filteredEmployee;
            },
             ...mapGetters({
                username: 'auth/user',
                error: 'form/error-states',
                is_visible_vp: 'modals/people_vehicle_act'
            }),
        };
        this.watch = {
            id(newvalue){
                if(this.id != null && this.id != newvalue){
                    this.id = newvalue;
                    this.init();
                }
            },
            vehicles(){
                this.init();
            },
            employees(){
                this.init();
            },
        };
        this.props = ['id', 'vehicles', 'employees', 'only_read'];
    }).apply(AddpeoplevehicleDialog);
    export default AddpeoplevehicleDialog;
</script>

<style scoped>

</style>
