<template>
    <span>
        <Dialog :header="'Gestion Actividad'" v-model:visible="isVisible" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '98vw'}" :closable="false" >
            <div class="p-grid p-nogutter p-d-flex p-justify-center p-ai-center" style="height: 100%; min-height: 20vh">

                
                
            </div>
            <template #footer>
                <buttonp label="Cerrar" icon="pi pi-times" @click="closeModal();" autofocus />
            </template>
        </Dialog>
    </span>
</template>

<script>

    import {mapGetters} from 'vuex';
    //import ActivityService from '@/service/ActivityService';  

    let EditActImplDialog = {};
    (function () {

        this.name = 'editactimpl-dialog';

        this.created = function () {
            //load implementation
        };
        this.data = function () {
            return {
               
            }
        };
        this.methods = {
            
             closeModal(){
                this.$store.dispatch('modals/set-actimpl', false);
            },

        };
        this.mounted = function () {

        };
        this.components = {}
        this.computed = {
             ...mapGetters({
                username: 'auth/user',
                error: 'form/error-states',
                isVisible: 'modals/act_impl'
            }),
        };
        this.watch = {
        };
        this.props = ['id'];
    }).apply(EditActImplDialog);
    export default EditActImplDialog;
</script>

<style scoped>

</style>
