<template>
    <span>
        <Tag class="mr-2" v-if="status === 'SCHEDULED'"  severity="grey" :value="'PROGRAMADO ' + value"></Tag>
        <Tag class="mr-2" v-if="status === 'IN_CALENDAR'"  severity="info" :value="'CALENDARIZADO ' + value"></Tag>
        <Tag class="mr-2" v-if="status === 'FINISH'"  severity="success" :value="'FINALIZADO ' + value"></Tag>
        <Tag class="mr-2" v-if="status === 'IN_PROGRESS'" severity="warning" :value="'EN CURSO ' + value" style="background-color: grey;"></Tag>
        <Tag severity="danger" v-if="status === 'NEVER_START'" :value="'NO COMENZO ' + value"></Tag>
    </span>
</template>

<script>
   
    let StatusTag = {};
    (function () {

        this.name = 'status-tag';
        this.created = function () {
        };
        this.data = function () {
            return {
            }
        };
        this.methods = {
        };
        this.mounted = function () {};
        this.components = {};
        this.computed = {};
        this.watch = {};
        this.props = ['status', 'value'];
    }).apply(StatusTag);
    export default StatusTag;
</script>


<style scoped>
  

</style>

