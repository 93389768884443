<template>
    <span>
        <Dialog :header="'Subir Comprobante Actividad'" v-model:visible="is_visible_finish" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '30vw'}" :closable="false" >
            <div class="p-grid p-nogutter p-d-flex p-justify-center p-ai-center" style="height: 100%; min-height: 20vh">
                <div class="md-min-width card m-3">
                    <h5 class="text-black m-3"></h5>
                    <br /><br />

                    <div class="field mt-4">
                      <h5 class="text-black">Archivo actividad</h5>
                      <FileUpload mode="basic" name="demo[]" url="./upload" chooseLabel="Ingrese archivo de pagina" :customUpload="true" @uploader="myUploader"/>
                    </div>

                    <!--buttonp class="w-8rem mt-5 p-button-warning" icon="pi pi-save" label="Guardar" @click.prevent="edit_url()"/-->



                </div>
            </div>
            <template #footer>
                <buttonp label="Cerrar" icon="pi pi-times" @click="closeModal();" autofocus />
            </template>
        </Dialog>
    </span>
</template>

<script>

    import {mapGetters} from 'vuex';
    import ActivityService from '@/service/ActivityService';  
    import UploadFileService from '@/service/UploadFileService'; 

    let UploadfileDialog = {};
    (function () {

        this.name = 'uoloadfile-dialog';

        this.created = function () {
        };
        this.data = function () {
            return {
                name: '',
                activity: {name:null, contract_id: null, is_enable: true, url_file: null, activity_type_id: null},
                contracts: [],
                activitiesType: [],
                selectedActivityType: null,
                selectedContract: null,
                upload_data_file: null,
                start_act: null,
                finish_act: null,
                static_url: process.env.VUE_APP_STATIC,
                rutaArchivoSubido: null,
            }
        };
        this.methods = {
            proccessmessageResponse(response){
                var isError = true
                if(response.data.status == 'OK') 
                    isError = false;
                this.$store.dispatch('form/result', {isError: isError, message: response.data.message});
            },
             myUploader(event){
                 this.$store.dispatch('form/submit', true);
                // upload data to the server
                 let result = {isError: false, message: null}
                 var fileList = event.files;
                const formData = new FormData();
                formData.append ('docs', fileList[0]);
                formData.append ('destination', "activity-implementation/");
                formData.append ('id', this.id);
                formData.append ('not_uuid', true);

                UploadFileService.uploadfile(formData).then((response) => {
                    this.proccessmessageResponse(response);
                    this.$store.dispatch('form/submit', false);
                    this.rutaArchivoSubido = response.data.file_path;
                      ActivityService.updateimg(this.id, this.rutaArchivoSubido).then((response) => {
                          this.proccessmessageResponse(response);
                          this.$store.dispatch('form/submit', false);
                          this.$emit("refresh", {});
                          this.closeModal();
                      }).catch((error) => {
                          console.log(error);
                          this.$store.dispatch('form/submit', false);
                      });
                }).catch((error) => {
                    result.isError = true;
                    if (error.data) {
                        result.message = error.data.message;
                    } else {
                        result.message =  'Ha ocurrido un error interno(uploadfile)'
                    }
                    this.$store.dispatch('form/result', result);
                    this.$store.dispatch('form/submit', false);
                })
            },
            addCalendar(){

            },
            closeModal(){
                this.$store.dispatch('modals/set-uploadact', false);
            },
            loadImplementation(id){
                console.log(id);
            
            }
        };
        this.mounted = function () {
            this.loadImplementation(this.id);
        };
        this.components = {}
        this.computed = {
             ...mapGetters({
                username: 'auth/user',
                error: 'form/error-states',
                is_visible_finish: 'modals/upload_act'
            }),
        };
        this.watch = {
        };
        this.props = ['id'];
    }).apply(UploadfileDialog);
    export default UploadfileDialog;
</script>

<style scoped>

</style>
