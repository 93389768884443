<template id="calendarActivity">
        <div >
            <div v-if="getactivitiesCalendar.length > 0 && finishLoadCalendar == true">
                <TabView class="mt-5" style="width: : 100vw !important;">
                    <TabPanel header="Calendario Actividades">
                            <div v-if="getactivitiesCalendar.length > 0" style="z-index: -10 !important;">
                                <FullCalendar :events="getactivitiesCalendar" :options="optionsCalendar"/>
                                <br /><br /><br /><br /><br />
                            </div>
                            <div v-else>
                                <h2>Sin Actividades</h2>
                            </div>
                            
                    </TabPanel>
                    <TabPanel header="Tabla Actividades">
                        <DataTable id="tableActivity" :value="allActivities" responsiveLayout="scroll">
                            <Column field="activity_implementation_id" header="ID"></Column>
                            <Column field="activity.name" header="Actividad"></Column>
                            <Column field="activity.activity_type.name" header="Tipo Actividad"></Column>
                            <Column field="status" header="ESTADO">
                                <template #body="slotProps">
                                    <status-tag :status="slotProps.data.status" :value="''"></status-tag>
                                </template>
                            </Column>
                             <Column header="Calenderiazda Comienzo">
                                <template #body="slotProps">
                                 <span v-if="slotProps.data.schedule_start">{{utcToLocal(slotProps.data.schedule_start)}}</span>
                                 </template>
                             </Column>
                            <Column header="CalendariazdTermino">
                                <template #body="slotProps">
                                <span v-if="slotProps.data.schedule_finish">
                                    {{utcToLocal(slotProps.data.schedule_finish)}}
                                </span>
                                </template>
                            </Column>
                            <Column header="Comienzo">
                                <template #body="slotProps">
                                <span v-if="slotProps.data.activity_start">
                                    {{utcToLocal(slotProps.data.activity_start)}}
                                </span>

                                </template>
                            </Column>
                            <Column header="Termino">
                                <template #body="slotProps">
                                    <span v-if="slotProps.data.activity_finish">
                                        {{utcToLocal(slotProps.data.activity_finish)}}
                                    </span>
                                </template>
                            </Column>
                            <!--Column  header="Acciones"></Column-->
                        </DataTable>
                        <br /><br /><br /><br /><br />
                    </TabPanel>
                </TabView>
            </div>
            <div v-else>
                <h2>Sin Actividades</h2>
            </div>
        </div>
</template>


<script>
    import {mapGetters} from 'vuex';
    import ActivityService from '@/service/ActivityService';
    import '@fullcalendar/core/vdom';
    import dayGridPlugin from '@fullcalendar/daygrid';
    import timeGridPlugin from '@fullcalendar/timegrid';
    import interactionPlugin from '@fullcalendar/interaction';
    import esLocale from '@fullcalendar/core/locales/es';
    import moment from "moment";
    import StatusTag from '@/include/StatusTag'


    let CalendarActivity = {};
    (function () {
        this.name = 'calendar-activity';

        this.created = function () {
            this.getAllActivitiesUser();
        };

        this.mounted  = function () {

        };

        this.data = function () {
            return {
                name: '',
                optionsCalendar: {
                    height: '800px',
                    plugins:[dayGridPlugin, timeGridPlugin, interactionPlugin],
                    initialDate : new Date(),
                    headerToolbar: {
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth,timeGridWeek,timeGridDay'
                    },
                    selectMirror: true, 
                    dayMaxEvents: true,
                    selectable: true,
                    editable: false,
                    locale: esLocale,
                    eventClick: (e) =>  {
                        //handle date click
                        this.editActivity(e.event._def.publicId);
                    }
                },
                activitiesCalendar: [],
                allActivities: [],
                finishLoadCalendar: false,
            }
        };
        this.methods = {
             utcToLocal(date){
                if(date){
                  var stillUtc = moment.utc(date).toDate();
                  var local = moment(stillUtc).local().format('DD-MM-YYYY HH:mm');
                  return local;
                }
              },
               utcToLocalCalendar(date){
                if(date){
                  var stillUtc = moment.utc(date).toDate();
                  var local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm');
                  return local;
                }
              },
            editActivity(){
                console.log('go activity implementation');
            },
            getAllActivitiesUser() {
                this.$store.dispatch('form/submit', true)
                let result = {isError: false, message: null}
                ActivityService.allActiImplCreator()
                    .then(response => {
                         this.allActivities = response.data;
                    if(response.data.length === 0){
                        result.isError = true;
                        result.message = 'Sin resultados';
                        this.$store.dispatch('form/result', result);
                    }

                    for (var i = 0; i < this.allActivities.length; i++) {
                        if(this.allActivities[i].activity_start !== null && this.allActivities[i].activity_finish !== null){
                            var titlleeee = this.allActivities[i].activity.name + '('+this.allActivities[i].activity.activity_type.name+')';
                            let evetTemp = {
                                "id": this.allActivities[i].activity_implementation_id,
                                "title": titlleeee,
                                "start": this.utcToLocalCalendar(this.allActivities[i].activity_start),
                                "end": this.utcToLocalCalendar(this.allActivities[i].activity_finish),
                            }
                            this.activitiesCalendar.push(evetTemp);
                        }
                        }

                    this.finishLoadCalendar = true;
                    this.$store.dispatch('form/submit', false);
                    })
                    .catch(err => {
                      console.log(err);
                       this.$store.dispatch('form/submit', false)
                    });
                    
            },
        };
        this.computed = {
            getactivitiesCalendar(){
                return this.activitiesCalendar;
            },
            ...mapGetters({
                username: 'auth/user'
            }),
        };
        this.watch = {

        };
        this.props = [];
        this.components = {StatusTag};
    }).apply(CalendarActivity);

    export default CalendarActivity
</script>
